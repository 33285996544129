import { Home, ResponsiveContext } from 'components'
import { getContentFulComponents } from 'shared/helpers'
import { MetaHead } from '../components'
import { dehydrate } from '@smu-chile/pkg-unimarc-hooks'
import { PropsHome } from 'shared/interfaces/Home'

const Index = ({
  data,
  isMobile,
  salesChannel,
  seoInfo
}: PropsHome): React.ReactElement => {
  return (
    <ResponsiveContext isMobile={isMobile}>
      <>
        <MetaHead
          contentDescription={seoInfo?.description}
          titleName={seoInfo?.pageTitle}
          type='HOME'
        />
        <Home
          data={data}
          isMobile={isMobile}
          salesChannel={salesChannel}
        />
      </>
    </ResponsiveContext>
  )
}

export async function getServerSideProps({ req }) {
  const contentOptions = {
    options: {
      content_type: 'home',
      'fields.idFormato': 2,
      limit: 1,
      include: 2
    }
  }
  const {
    response: data,
    queryClient,
    seoInfo
  } = await getContentFulComponents({ contentOptions })

  return {
    props: {
      data,
      salesChannel:
        req.cookies.co_sc ||
        process.env.NEXT_PUBLIC_DEFAULT_SALES_CHANNEL ||
        '1',
      seoInfo,
      dehydratedState: dehydrate(queryClient)
    }
  }
}
export default Index
